export const quizData = [
    {
        question: 'Что вам нужно?',
        options: ["Кровля", "Фасад"]
    },
    {
        path: 'Кровля',
        question: 'Что именно?',
        options: ["Металлочерепица", "Гибкая черепица", "Профнастил"],
        secondOptions: ["Площадь м2", "Вид черепицы"]
    },
    {
        path: 'Фасад',
        question: 'Что именно?',
        options: ["Металлосайдинг ", "Фасадные панели", "Виниловый сайдинг"],
        secondOptions: ['Квадратура м2', "Цвет"]
    }
]
